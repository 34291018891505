<template>
    <div>
        <form v-if='model'>
            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='yaml'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='yaml'
                                   type='text'
                                   v-model="model.yaml"
                        />
                        <label for='yaml'>Yaml</label>
                    </span>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Factory from './model';
import Uploader from '../../../mixins/uploader';
export default {
    mixins: [Uploader],
    data() {
        return {
            model: null
        };
    },
    mounted() {
        this.model = Factory.modelCopy();
        this.prepareUploads(Factory.uploads, this.lang);
    },
};
</script>
