<template>
    <Card>
        <template #title>
            <h5>Carica il file di documentazione API</h5>
        </template>
        <template #content>
            <PlainForm/>
        </template>
    </Card>
</template>

<script>

import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import Guards from '../../../mixins/guards';

import PlainForm from './Plain';

export default {
    data(){
      return {};
    },
    mounted(){
    },
    methods: {
    },
    watch: {
    },
    mixins: [
        Notifications,
        Navigations,
        Guards
    ],
    components: {
        PlainForm
    }
}
</script>
