import tools from '../../../libs/tools';

const model = {
    yaml: "https://4books.s3.eu-west-1.amazonaws.com/images/ui/web/swagger/openapi.yaml"
};
const translation = {};

export default {
    ...tools.models.apiCommons(model, translation, ""),
    uploads: [
        { el: "yaml", target: "yaml", folder: "/images/ui/web/swagger", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.YAML]},
    ]
};
